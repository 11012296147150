@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
a:hover{
  text-decoration: none !important;
}
.carousel .carousel-status{
  display: none;
}
.carousel .control-dots{
  display: none;
}
.main_heading{
  font-size: 60px !important;
  font-family: 'Poppins', sans-serif !important;
  line-height: 75px !important;
  margin: 0px;
  margin-bottom: 10px;
}
.header_first{
  padding: 15px 0px;
  width: 100%;
}
.header_register{
  padding: 15px 0px;
  width: 100%;
}
.header_register .header_link{
  color: #0A6DD4 !important;
}
.header_register .header_button_link{
  background-color: #0A6DD4;
  color: #fff !important;
}
.mobile_section{
  display: none;
}
.custom_container{
  width: 90%;
  margin: auto;
}
.header_link{
  color: #0A83FF !important;
  font-weight: 400;
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
  margin-right: 40px;
}
.header_link:hover{
  text-decoration: none !important;
}
.header_button_link{
  background-color: #0A83FF;
  color: #fff !important;
  font-weight: 400;
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
  padding: 5px 25px;
  border-radius: 5px;
}
.header_button_link:hover{
  text-decoration: none !important;
}
.header_price{
  background-color: #0A83FF;
  padding: 10px 0px;
}
.header_price img{
  width: 70%;
}

/* banner css */

.banner_section{
  background-image: url('./assets/images/banner_bg.png');
  height: 878px;
  background-repeat: no-repeat;
  background-position: top right;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.banner_bg_color{
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 72.14%);
  padding: 150px 0px 30px;
  margin-top: 370px;
}

.btn_primary{
  background-color: #0A83FF !important;
  color: #fff !important;
  font-size: 24px !important;
  font-family: 'Poppins', sans-serif;
  display: inline-block;
  border: 1px solid #0A83FF;
  font-weight: 400 !important;
  padding: 20px 40px !important;
  border-radius: 25px !important;
  height: unset !important;

}

.banner_section .banner_text_section h2{
  font-size: 60px;
  font-family: 'Poppins', sans-serif;
  line-height: 75px;
  margin-bottom: 10px;
  text-align: center;
}
.banner_section .banner_text_section p{
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
  line-height: 30px;
  margin-bottom: 40px;
  font-weight: 400;
  text-align: center;
}

/*banner css*/

/*marquee_section css*/

.marquee_bg_section{
  background-color: #CEE6FF;
  padding: 10px 0px;
  margin: 50px 0px 150px;
}
.marquee_bg_section ul{
  display: flex;
}
.marquee_bg_section ul li{
  margin-right: 60px;
  font-size: 50px;
  color: #fff;
  font-family: 'Poppins', sans-serif;
}

/*marquee_section css*/

/*Captivating css*/

.craft_captivating_section{
  margin-top: 50px;
  margin-bottom: 50px;
}
.craft_captivating_section p{
  width: 65%;
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
  line-height: 30px;
  font-weight: 400;
  margin: 20px auto 50px;
}
.craft_captivating_section .img_card{
  margin: 30px auto;
  width: 25%;
}
.craft_captivating_section .img_card .img_box{
  width: 166px;
  height: 166px;
  background-color: #CEE6FF;
  border-radius: 50%;
  margin: 0px auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.craft_captivating_section .img_card .card_text_section h2{
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
  line-height: 30px;
  font-weight: 500;
  width: 56%;
  margin: 20px auto 10px;
  text-align: center;
}
.craft_captivating_section .img_card .card_text_section p{
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  line-height: 21px;
  font-weight: 400;
  width: 80%;
  margin: 0px auto 20px;
  text-align: center;
}

/*Captivating css*/

/*trusted brand css*/

.trusted_brand_section{
  background-color: #CEE6FF;
  padding: 80px 0px;
  border-radius: 50px;
  margin: 200px 0px;
}
.trusted_brand_section .img_box{
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

/*trusted brand css*/

/*revolution audience css*/

.revolution_audience_section{
  margin: 80px 0px;
}
.revolution_audience_section .text_section p{
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
  line-height: 30px;
  font-weight: 400;
  margin: 15px 0px;
  text-align: left;
}

/*revolution audience css*/

/*check out story builder css*/

.check_story_section{
  margin-top: 70px;
  margin-bottom: 70px;
}
.check_story_section .tabs_section{
  margin-top: 70px;
}
.check_story_section .tab_link{
  color: #CDCDCD;
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
  line-height: 30px;
  font-weight: 400;
  background-color: transparent;
  border: 0px;
  width: 300px;
  border-right: 1px solid #CDCDCD;
}
.check_story_section .tab_link[aria-selected="true"]{
  color: #000000;
}
.check_story_section .tab_link:last-child{
  border-right: 0px;
}
.check_story_section .tabs_section .tabs_bg{
  background-color: #CEE6FF;
  border-radius: 75px;
  height: 750px;
  width: 1250px;
  margin: 0px auto 20px;
}
.check_story_section .tabs_inner_section .tabs_button{
  background-color: #0A83FF;
  border-radius: 20px;
  padding: 15px 25px;
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
  line-height: 30px;
  font-weight: 400;
  color: #fff;
  border: 1px solid #0A83FF;
  height: unset;
}

/*check out story builder css*/

/*Design Templates Css*/

.design_templates_section .text_section{
  width: 50%;
  margin: 130px auto 50px;
}
.design_templates_section .text_section p{
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
  line-height: 30px;
  font-weight: 400;
  margin: 15px 0px;
  color: #000;
  text-align: center;
}
.design_templates_section .slider-card{
  height: unset;
}
.design_templates_section .slider-card .slider-card-title{
  margin: 0px !important;
}

.item-slider {
  padding: 0px 5px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.item-slider .item-container {
  display: inline-flex;
  justify-content: flex-start;
  overflow-x: scroll;
  padding: 0px 5px;
  margin-bottom: 100px;
}

.item-slider .item-container span {
  margin: 0px 0px;
}

.item-slider .item-container::-webkit-scrollbar {
  display: none;
}

.item-slider .left-arrow-left {
  position: absolute;
  left: 15px;
  cursor: pointer;
  z-index: 1;
}

.item-slider .right-arrow-right {
  position: absolute;
  right: 0px;
  cursor: pointer;
  z-index: 1;
}

.right-arrow-right>svg,
.left-arrow-left>svg {
  color: #c3c3c1;
}

.right-arrow-right svg:hover,
.left-arrow-left svg:hover {
  color: #5b5b5b;
  border-radius: 50%;
}

/* Card item styles */
.item-container .card {
  border: unset;
}

.item-container .card-title {
  font-size: 14px;
  font-weight: normal;
}

.item-container .card span {
  padding: 0;
  margin: 0;
}

.item-container .card .mrp-price {
  font-size: 14px;
  font-weight: normal;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}

.item-container .card .save-price {
  font-size: 14px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: var(--sp-exam-button-color);
}

.item-container .card .actual-price {
  font-size: 14px;
  font-weight: 500;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
  
/*Design Templates Css*/

/*Benefit Using Woosh Css*/

.benefit_woosh_section .text_section{
  width: 50%;
  margin: 50px auto 0px;
}
.benefit_woosh_section .text_section p{
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
  line-height: 30px;
  font-weight: 400;
  margin: 15px 0px;
  color: #000;
  text-align: center;
}
.benefit_woosh_section .accordion_section{
  width: 45%;
}
.benefit_woosh_section .benefit_box_section{
  width: 45%;
}
.woosh_accrodion_section .chakra-accordion__item{
  border-bottom: 1px solid #CACACA;
}
.woosh_accrodion_section .chakra-accordion__item h2{
  margin: 0px;
}
.woosh_accrodion_section .chakra-accordion__item .chakra-accordion__button{
  background-color: transparent;
  border: 0px;
  padding: 20px 10px 20px 50px; 
}
.woosh_accrodion_section .chakra-accordion__item .chakra-accordion__button .btn_text{
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
  line-height: 30px;
  font-weight: 400;
  margin: 0px;
}
.woosh_accrodion_section .chakra-accordion__item .chakra-accordion__button .chakra-accordion__icon{
  font-size: 30px;
  color: #CDCDCD;
}
.woosh_accrodion_section .chakra-accordion__item .chakra-accordion__panel{
  padding: 0px 10px 20px 50px;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  line-height: 21px;
  font-weight: 400;
}

/*Benefit Using Woosh Css*/

/*Bottom Stories Section Css*/

.bottom_stories_section{
  background-color: #0A83FF;
  padding: 30px 70px;
  border-radius: 75px;
  position: relative;
  height: 865px;
  display: flex;
  align-items: center;
  margin-bottom: 80px;
  margin-top: 150px;
}
.bottom_stories_section .text_section{
  width: 80%;
  text-align: left;
}
.bottom_stories_section .text_section h2{
  font-size: 60px;
  font-family: 'Poppins', sans-serif;
  line-height: 70px;
  font-weight: 700;
  margin: 0px 0px 15px;
  color: #fff;
  text-align: left;
}
.bottom_stories_section .text_section p{
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
  line-height: 30px;
  font-weight: 400;
  margin: 0px 0px 15px;
  color: #fff;
  text-align: left;
}
.bottom_stories_section .text_section .btn_stories_login{
  background-color: #fff;
  padding: 20px 50px;
  display: inline-block;
  border: 1px solid #fff;
  font-size: 24px;
  font-family: 'Poppins', sans-serif;
  line-height: 36px;
  font-weight: 400;
  color: #0A83FF;
  border-radius: 20px;
  margin-top: 50px;
  height: unset;
}
.bottom_stories_section .img_woosh_stories_bg{
  top: -12px;
  right: -20px;
  position: absolute;
}

/*Bottom Stories Section Css*/


/*Pricing Page Css*/

.pricing_page .chakra-tabs__tablist{
  justify-content: center;
  align-items: center;
  border-bottom: 0px;
  background-color: #fff;
  box-shadow: 0px 0px 2px 2px rgba(0,0,0,0.10);
  border-radius: 100px;
  padding: 10px;
  width: 360px;
  margin: auto;
}
.pricing_page .chakra-tabs__tablist button{
  padding: 10px 50px;
  font-size: 20px;
  font-family: 'Poppins';
  font-weight: 400;
  color: #000;
  border-bottom: 0px;
}
.pricing_page .chakra-tabs__tablist button[aria-selected=true], .pricing_page .chakra-tabs__tablist button[data-selected]{
  color: #fff;
  border: 0px;
  background-color: #0A83FF;
  border-radius: 30px;
}


.btn{
  color: #fff;
  background-color: #0A83FF;
  border: none;
  font-size: "14px";
}

.best-pkg{
 margin-top: 50px;
}

.text_section p{
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
  padding-top: 0;
  font-family: Poppins;
  margin: 0px 0px 30px;
}

.btns{
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

}

.bb{
  background-color: rgba(10, 131, 255, 1);
  color: #fff;
  border: none;
  height: 35px;
  border-radius: 20px;
  padding: 0 23px;
  font-size: 14px;
}

.cc{
  background-color: rgba(255, 255, 255, 1);
  border: none;
  height: 35px;
  border-radius: 20px;
  padding: 0 23px;
  font-size: 14px;
}


.main_card{
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  margin: 0px 0px 50px;
}

.price_card_section{
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
  margin-right: 30px;
  border-radius: 20px;
  width: 400px;
  position: relative;
}
.price_card_section .badge_text_section{
  background-color: #E30000;
  position: absolute;
  padding: 4px 12px;
  border-radius: 100px;
  right: 20px;
  top: -20px;
}
.mt_50{
  margin-top: 50px;
}
.price_card_section .badge_text_section p{
  font-size: 18px;
  font-weight: 700;
  font-family: 'Poppins';
  color: #fff;
}
.price_card_section .head_section{
  background-color: #0966C4;
  text-align: center;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  padding: 40px 0px;
}
.main_card .price_card_section:last-child{
  margin-right: 0px;
}

.first{
  color: #fff;
  margin: 0px 0px 5px;
  font-size: 48px;
  line-height: 56px;
  font-weight: 700;
  font-family: 'Poppins';
 
}

.Month_name{
  color: #fff;
  margin: 0;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  font-family: 'Poppins';
}

.vecter{
  color: rgba(10, 131, 255, 1);
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.vecter svg{
  font-size: 20px;
  margin-right: 15px;
}
.vecter p{
  font-size: 20px;
  color: #000;
  font-family: 'Poppins';
  line-height: 28px;
}

.Check{
  flex-direction: row;
  display: flex;

}

.box{
/*  text-align: center;
    padding: 30px 0 15px 80px;*/
  margin: 20px 40px 50px;
}

.bottom{
  text-align: center;
}

.mrg{
  margin: 0;
  color: rgba(172, 172, 172, 1);
  padding-bottom: 5px;
  font-size: 24px;
}

.strong{
    color: rgba(45, 45, 45, 1);
    font-size: 32px;
    font-family: Poppins;
    font-weight: 600;
    line-height: 40px;
}

.mrg1{
  margin: 0;
  font-family: Poppins;
  font-size: 16px;
  color: rgba(45, 45, 45, 1);
}

.btan{
     border: 0px !important;
    /* height: 32px; */
    background-color: rgba(10, 131, 255, 1) !important;
    padding: 20px 35px !important;
    margin: 35px 0px 50px !important;
    border-radius: 20px !important;
    color: rgba(255, 255, 255, 1) !important;
    height: unset !important;
    font-size: 16px !important;
    font-family: Poppins !important;
    font-weight: 400 !important;
}

.trusted_by_section{
  background-color: rgba(206, 230, 255, 1);
  padding: 50px 0px;
}

.trusted_by_section .box_img{
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.main_leticia{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 80px 0px 130px;
}

.Leticia_Kutch{
    text-align: center;
    background: rgba(10, 131, 255, 1);
    width: 900px;
    padding: 33px;
    border-radius: 30px;
}

.Leticia_Kutch h2{
  color: rgba(255, 255, 255, 1);
  font-size: 20px;
  font-weight: 800;
  line-height: 28px;
  font-family: Poppins;
}

.Leticia_Kutch .ceo{
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  font-family: Poppins;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 30px;
}

.Leticia_Kutch .make_stories{
  color: rgba(255, 255, 255, 1);
  font-size: 20px;
  font-family: Poppins;
  font-weight: 400;
  line-height: 30px;
  width: 85%;
  margin: 0px auto 40px;
}

.Icons{
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.Icons strong{
  color: #fff;
  font-size: 36px;
  font-family: "Manrope", sans-serif;
  font-weight: 500;
  line-height: 19.92px;
  padding: 16px 0;
}

.Icons .rating{
  color: rgba(185, 200, 243, 1);
  font-size: 24px;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  line-height: 30px;
  padding: 16px 0;
}
.star_section{
  margin-left: 10px;
}
.star_section svg{
  color: #fff;
}

.not_sure{
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 100px;
  margin-bottom: 100px;
}

.not_sure .let{
  font-size: 20px;
  line-height: 30px;
  font-family: Poppins;
  color: #000;
  padding: 10px 0px 0px;
}

.pricing_plan{
  width: 50%;
  padding-top: 0px;
/*  margin: 0 150px;*/
}
.pricing_plan .main_heading{
  color: #02324F;
}
.not_sure .images{
  width: 50%;
}
.not_sure .images img{
  float: right;
}

.pricing_plan .Text{
  font-size: 14px;
  padding: 0 72px 0 0;
}

.img2{
  height: 450px;
}

.footer{
    background: rgba(10, 131, 255, 1);
    padding: 50px;
}

/*Pricing Page End Css*/

/*Landing Page Css*/
.coming_page{
  background-color: #0A83FF;
    height: 100vh;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.coming_page img {
    margin: 0px 0px 30px;
}

.coming_page .head_section {
    display: flex;
    align-items: center;
    justify-content: center;
}

.coming_page .head_section h1 {
    font-size: 70px;
}

.coming_page .head_section h1 {
    font-family: 'Poppins', sans-serif;
    font-size: 70px;
    color: #fff;
    font-weight: 500;
    margin: 0px 20px 0px 0px;
}
.img-fluid {
    max-width: 100%;
    height: auto;
    display: inline-block;
}

.text_section {
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    text-align: center;
    padding-top: 0;
    font-family: Poppins;
    margin: 0;
}

.coming_page h3 {
    font-size: 42px;
}
.coming_page h3 {
    font-family: 'Poppins', sans-serif;
    font-size: 42px;
    color: #fff;
    font-weight: 400;
    margin: 0px;
}
.h3, h3 {
    font-size: 1.75rem;
}
.coming_page .field_section {
    border: 1px solid #fff;
    display: flex;
    align-items: center;
    width: 690px;
    justify-content: center;
    margin: 40px auto 0px;
    border-radius: 50px;
}
.coming_page .field_section button {
    background-color: #FFFFFF33;
    border: 0px;
    padding: 15px 34px;
    border-radius: 0px 50px 50px 0px;
    border-left: 1px solid #fff;
    font-size: 20px;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    color: #fff;
    display: inline-block;
    height: unset;
}
.coming_page .field_section input {
    width: 69%;
    background-color: transparent;
    border: 0px;
    margin: 0px 20px;
    font-size: 20px;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    color: #fff;
}
.coming_page  .privacy_link {
    font-size: 20px;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    color: #fff;
    position: absolute;
    bottom: 20px;
    right: 0;
    left: 0;
    text-decoration: none;
}
.coming_page .Linked{
  background-color: #fff;
  color: #0A83FF;
  font-weight: 400;
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
  padding: 5px 25px;
  border-radius: 5px;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}
.coming_page .Linked:hover{
  text-decoration: none;
}
.partner_section{
  background-color: #E9F4FF;
  padding: 170px 0px;
}
.partner_section .text_section{
  width: 60%;
  margin: auto;
}
.partner_section .text_section .main_heading{
  color: #02324F;
}
.partner_section .text_section .partner_field_section{
  background-color: #fff;
  padding: 18px 20px;
  height: unset;
  border-radius: 35px;
  border: 0px;
}
.pricing_form_section{
  padding: 100px 0px;
}
.pricing_form_section .text_section{
  width: 40%;
}
.pricing_form_section .text_section p{
  font-size: 28px;
  line-height: 42px;
}
.pricing_form_section .text_section .main_heading{
  color: #02324F;
}
.pricing_form_section .form_bg_section{
  width: 50%;
  background-color: #CEE6FF;
  padding: 50px 70px;
  border-radius: 65px;
}
.pricing_form_section .form_bg_section .field_section{
  margin-bottom: 30px;
}
.pricing_form_section .form_bg_section .field_section label{
  font-size: 14px;
  line-height: 21px;
  color: #02324F;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  margin-bottom: 10px;
  display: block;
}
.pricing_form_section .form_bg_section .field_section input{
  border: 0.5px solid #02324F;
  border-radius: 15px;
  padding: 15px 20px;
  height: unset;
}
.pricing_form_section .form_bg_section .field_section select, .pricing_form_section .form_bg_section .field_section textarea{
  border: 0.5px solid #02324F;
  border-radius: 15px;
  padding: 15px 20px;
  height: unset;
}
.pricing_bottom_section{
  margin: 70px 0px 150px;
}
.pricing_bottom_section .text_section{
  width: 70%;
}
.pricing_bottom_section .text_section p{
  color: #02324F;
  margin: 0px;
}
.pricing_bottom_section .text_section .main_heading{
  color: #02324F;
}
.pricing_bottom_section .text_section .inner_para_section{
  border-left: 8px solid #0A83FF;
  width: 80%;
  padding-left: 20px;
}
.pricing_bottom_section .text_section .inner_para_section p{
  text-align: left !important;
  color: #02324F;
  font-style: italic;

}
.pricing_bottom_section .image_section{
  width: 30%;
}

/*Register Page Css*/


/*.SignUpForm{
  background-color: #0A83FF;
  
  padding-left: 30px !important;
}
.SignUpForm input, .SignUpForm select {
  margin-bottom: 20px;
  color: white;
  border: none; 
  border-bottom: 1px solid #fff;
  box-shadow: none;
  border-radius: 0px;
   outline: none;
}

.SignUpForm input:hover, .SignUpForm select:hover {
  box-shadow: none; 
}
.SignUpForm select, svg{
  color: #fff !important;
  margin-bottom: 20px;
}
.SignUpForm option{
  color: #000;
}

.SignUpForm label, p{
  color: #fff !important;
}

.SignUpForm .Linked{
  background-color: #fff;
  color: #0A83FF;
  font-weight: 400;
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
  padding: 5px 25px;
  border-radius: 5px;
  cursor: pointer;
}

.right_section p{
  color: #000 !important;
  width: 70%;
}
.right_section h2{
  color: #000 !important;
  font-size: 17px ;
  font-weight: 600;
}
*/

/* Improve styling for the right section */
.right_section{
  width: 100%;
  padding: 100px 0px 50px;
}
.right_section .box_section{
  text-align: center;
  margin-bottom: 50px;
  width: 25%;
}
.right_section .box_section .circle_box_section{
  width: 100px;
  height: 100px;
  background-color: #CEE6FF;
  border-radius: 50px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.right_section .box_section p {
  color: #000 !important;
  width: 70%;
  margin: 0px auto;
  font-size: 14px; 
  line-height: 1.5; 
  font-family: 'Poppins', sans-serif;
}

.right_section .box_section h2 {
  color: #000 !important;
  font-size: 20px; /* Increase font size */
  font-weight: 500;
  margin-top: 10px; /* Add some spacing between sections */
  font-family: 'Poppins', sans-serif;
  width: 60%;
  margin: auto;
}

/* Improve styling for the sign-up form */
.signup_page{
  background-image: url('./assets/images/signup_img/signup_bg_img.png');
  background-repeat: no-repeat;
}
.SignUpForm {
  width: 80%;
  margin: 0px auto;
}
.form_section{
  width: 100%;
  margin-top: 50px;
  background: rgba(255, 255, 255, 0.75);
  border-radius: 68px;
  padding: 70px 0px;
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
}
.form_section .main_heading{
  font-size: 36px !important;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  color: #000;
  line-height: 36px !important;
  margin-bottom: 20px;
  text-align: center;
}
.form_section .main_inner_heading{
  font-size: 12px;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  color: #000;
  line-height: 14px;
  margin-bottom: 30px;
  text-transform: uppercase;
  text-align: left;
}
.form_section .field_section{
  margin-bottom: 20px;
}
.form_section .form_row_section .first_section{
  width: 50%;
  margin-right: 30px;
}
.form_section .form_row_section .second_section{
  width: 50%;
}
.form_section .field_section label{
  font-size: 12px;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  color: #000;
  line-height: 14px;
  margin-bottom: 10px;
  text-transform: uppercase; 
}
.form_section .btn_section{
  width: 540px;
  margin: auto;
}
.form_section .btn_create{
  background-color: #0A83FF;
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  padding: 15px 30px;
  display: inline-block;
  height: unset;
  border-radius: 10px;
}
.form_section .btn_create:hover{
  opacity: 75%;
  background-color: #0A83FF;
}
.agree_section{
  text-align: center;
  margin: 40px 0px;
}
.agree_section .chakra-checkbox__label{
  font-size: 14px;
  font-weight: 400;
  color: #000;
  font-family: 'Poppins', sans-serif;
}
.SignUpForm input,
.SignUpForm select {
  margin-bottom: 20px;
  color: #000;
  border: none;
  border-bottom: 1px solid #E9E9E9;
  box-shadow: none;
  border-radius: 0px;
  outline: none;
}

.SignUpForm input:hover,
.SignUpForm select:hover {
  box-shadow: none;
}

.SignUpForm select,
.SignUpForm svg {
  color: #000 !important;
  margin-bottom: 20px;
}
.SignUpForm .chakra-checkbox svg{
  margin-bottom: 0px;
}

.SignUpForm option {
  color: #000;
}
.SignUpForm p {
  color: #000;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  text-align: center;
}

.SignUpForm .Linked {
  background-color: #fff;
  color: #0a83ff;
  font-weight: 400;
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
  padding: 5px 25px;
  border-radius: 5px;
  cursor: pointer;
}

/*Footer Section Css*/

.footer_section{
  background-color: #CEE6FF;
  padding: 20px 0px 20px;
}
.footer_section .img_logo{
  margin: auto;
  margin-bottom: 30px;
}
.footer_section p{
  font-size: 14px;
  line-height: 19px;
  font-family: 'Poppins', sans-serif;
  color: #02324F;
  margin-bottom: 5px;
  font-weight: 400;
}
.footer_section a{
  font-size: 14px;
  line-height: 19px;
  font-family: 'Poppins', sans-serif;
  color: #02324F;
  margin: 0px;
  font-weight: 500;
}

/*Footer Section Css*/

/*Signup Success Page Css*/

.signup_success_page{
  height: 600px;
  justify-content: center;
  align-items: start !important;
  padding: 100px 0px 0px;
}
.signup_success_page .text_section .main_heading{
  font-size: 40px !important;
  line-height: 48px !important;
  color: #000;
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 40px;
}
.signup_success_page .text_section p{
  font-size: 24px;
  line-height: 32px;
  color: #000;
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 40px;
}
.signup_success_page .btn_login{
  background-color: #0A83FF;
  padding: 20px 30px;
  border-radius: 10px;
  color: #fff;
  font-size: 20px;
  line-height: 28px;
  display: inline-block;
}
.signup_success_page .btn_login:hover{
  text-decoration: none;
}

/*Signup Success Page Css*/

/* Improve styling for the footer */
.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #0a83ff;
  color: #fff;
}

.footer img {
  max-width: 150px;
}

.footer p {
  max-width: 70%;
  font-size: 14px;
}

/* Additional styling for links */
.footer a {
  color: #fff;
  text-decoration: underline;
}

.footer a:hover {
  color: #f0f0f0;
}
.footer_mobile_section{
  display: none;
}

/*About Page Start Css*/

.about_page .about_banner_section{
  padding: 120px 0px;
}
.about_page .about_banner_section .text_section p{
  font-size: 20px;
  line-height: 30px;
  color: #3C3C3C;
  font-family: 'Poppins', sans-serif;
  margin: 0px 0px 30px;
}
.about_page .about_banner_section .banner_bg_section{
  background-color: #E9F4FF;
  padding: 60px 0px;
  border-radius: 97px;
  text-align: center;
}
.about_page .about_banner_section .banner_bg_section img{
  margin: auto;
}
.about_page .about_banner_section .about_banner_inner_section{
  background-color: #fff;
  width: 368px;
  height: 654px;
  border-radius: 60px;
  margin: auto;

}
.about_page .about_mission_section{
  margin: 100px 0px;
}
.about_page .carousel .carousel-status{
  display: none;
}
.carousel.carousel-slider .control-arrow{
  top: 45% !important;
  color: #000 !important;
  height: 100px !important;
  background-color: #000 !important;
}
.about_page .about_mission_section .bg_section{
  background-color: #F2F8FF;
  border-radius: 87px;
  height: 618px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.about_page .about_different_section{
  background-color: #E9F4FF;
  border-radius: 94px;
  padding: 150px 0px;
  margin: 170px 0px 20px;
}
.about_page .about_different_section .text_section{
  width: 70%;
  margin: auto;
}
.about_page .about_card_section .card_section{
  background-color: #E9F4FF;
  border-radius: 60px;
  padding: 60px 20px;
  width: 450px;
  height: 750px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.about_page .about_card_section .item-slider .item-container span{
  margin: 0px 10px;
}
.about_page .about_card_section .item-slider .item-container span:nth-child(2n+2) .card_section .body_section{
  bottom: 50px;
  top: unset;
}
.about_page .about_card_section .item-slider .item-container span:nth-child(2n+2) .card_section .value_img{
  bottom: unset;
  top: 20px;
} 
.about_page .about_card_section .card_section .img_slider{
  width: 60%;
}
.about_page .about_card_section .card_section .value_img{
  position: absolute;
  bottom: 0px;
}
.about_page .about_card_section .card_section .body_section{
  position: absolute;
  width: 80%;
  top: 50px;
  left: 30px;
}
.about_page .about_card_section .card_section .body_section .card-title{
  font-size: 30px;
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
  line-height: 30px;
  margin: 0px 0px 10px;
}
.about_page .about_card_section .card_section .body_section .card-text{
  font-size: 20px;
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
  line-height: 30px;
}
.about_page .about_business_section{
  margin: 50px 0px;
}
.about_page .about_business_section .text_section{
  width: 80%;
  margin: 0px auto 30px;
}
.about_page .about_business_section .video_section{
  background-color: #02324F;
  border-radius: 100px;
  height: 800px;
}
.about_page .about_engage_section{
  margin-bottom: 150px;
}

/*About Page End Css*/

/*Contact Page Start Css*/

.contact_page .contact_banner_section{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 70px 0px 100px;
}
.contact_page .contact_banner_section .text_section{
  width: 45%;
}
.contact_page .contact_banner_section .text_section .main_heading{
  color: #02324F;
}
.contact_page .contact_banner_section .banner_bg_section{
  background-color: #02324F;
  border-radius: 65px;
  width: 45%;
  padding: 60px;
}
.contact_page .contact_banner_section .banner_bg_section .field_section{
  margin-bottom: 30px;
}
.contact_page .contact_banner_section .banner_bg_section .field_section label{
  font-size: 14px;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  line-height: 21px;
  color: #fff;
}
.contact_page .contact_banner_section .banner_bg_section .field_section input, .contact_page .contact_banner_section .banner_bg_section .field_section select, .contact_page .contact_banner_section .banner_bg_section .field_section textarea{
  border: 0.5px solid #fff;
  border-radius: 15px;
  padding: 12px;
  height: unset;
  color: #fff;
}
.contact_page .contact_crew_section{
  background-color: #E9F4FF;
  border-radius: 80px;
  padding: 140px 100px;
  position: relative;
  margin: 150px 0px 150px;
}
.contact_page .contact_crew_section .text_section{
  width: 62%;
  text-align: left;
}
.contact_page .contact_crew_section .text_section .main_heading{
  color: #02324F;
  text-align: left;
}
.contact_page .contact_crew_section .text_section p{
  color: #02324F;
  text-align: left !important;
  width: 75%;
}
.contact_page .contact_crew_section .text_section a{
  color: #0A83FF;
  display: flex;
  align-items: center;
}
.contact_page .contact_crew_section .text_section a img{
  position: unset;
  margin-left: 10px;
}
.contact_page .contact_crew_section img{
  position: absolute;
  top: 0;
  right: 130px;
}
.contact_page .contact_connect_section{
  margin: 150px 0px;
}
.contact_page .contact_connect_section .text_section{
  margin-bottom: 60px;
}
.contact_page .contact_connect_section .text_section .main_heading{
  color: #02324F;
}
.contact_page .contact_connect_section .connect_box_section{
  width: 145px;
  height: 145px;
  background-color: #CEE6FF;
  border-radius: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 30px;
}
.contact_page .contact_connect_section .connect_box_section:last-child{
  margin-right: 0px;
}
.contact_page .contact_power_section{
  background-color: #E9F4FF;
  padding: 100px 170px;
  border-radius: 83px;
}
.contact_page .contact_power_section .text_section .main_heading{
  color: #02324F;
}
.contact_page .contact_dive_section{
  margin: 150px 0px;
  text-align: center;
}
.contact_page .contact_dive_section .text_section{
  width: 80%;
  margin: 0px auto;
}
.contact_page .contact_dive_section .text_section .main_heading{
  color: #02324F;
} 
.contact_page .contact_dive_section a{
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
  padding-top: 0;
  font-family: Poppins;
  margin: 0px 0px 30px;
  color: #0A83FF;
  display: flex;
  align-items: center;
  justify-content: center;
}
.contact_page .contact_dive_section a img{
  margin-left: 10px;
}


/*Contact Page End Css*/

@media screen and (max-width: 1920px){
  .custom_container{
    width: 75%;
  }
  .pricing_plan{
    width: 45%;
  }
  .not_sure .images{
    width: 45%;
  }
}

@media screen and (max-width: 1680px){
  .custom_container{
    width: 80%;
  }
  .pricing_plan{
    width: 48%;
  }
  .not_sure .images{
    width: 48%;
  }
  .main_heading{
    font-size: 52px !important;
    line-height: 67px !important;
  }
}

@media screen and (max-width: 1440px){
  .custom_container{
    width: 90%;
  }
}

@media screen and (max-width: 1366px){
  .custom_container{
    width: 90%;
  }
  .check_story_section .tabs_section .tabs_bg{
    width: 100%;
  }
  .pricing_plan{
    width: 50%;
  }
  .not_sure .images{
    width: 50%;
  }
  .main_heading{
    font-size: 50px !important;
    line-height: 65px !important;
  }
}

/*Mobile Responsive Css*/

@media screen and (max-width: 767px){
  .desktop_section{
    display: none;
  }
  .mobile_section{
    display: block;
  }
  .header_first{
    position: relative;
  }
  .btn_primary{
    font-size: 12px !important;
    padding: 12px 30px !important;

  }
  .main_heading{
    font-size: 16px !important;
    line-height: 20px !important;
    text-align: center;
  }
  .header_link{
    color: #0A83FF !important;
    margin-top: 10px;
    display: block;
  }
  .banner_section{
    height: unset;
    margin: 0px 0px;
    background-size: cover;
  }
  .banner_section .banner_text_section{
    text-align: center;
  }
  .banner_bg_color{
    margin-top: 0px;
  }
  .banner_section .banner_text_section h2{
    font-size: 16px;
    line-height: 22px;
  }
  .banner_section .banner_text_section p{
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 20px;
  }
  .craft_captivating_section .img_card{
    width: 50%;
  }
  .craft_captivating_section .img_card .img_box{
    width: 81px;
    height: 81px;
    border-radius: 30px;
  }
  .craft_captivating_section .img_card .img_box img{
    width: 50%;
  }
  .craft_captivating_section .img_card .card_text_section h2{
    font-size: 12px;
    line-height: 18px;
    width: 60%;
  }
  .craft_captivating_section p{
    text-align: center;
    font-size: 12px;
    line-height: 18px;
    margin: 10px 0px 0px;
    padding: 0px 30px;
    width: 100%;
  }
  .revolution_audience_section{
    margin: 40px 0px 40px;
  }
  .revolution_audience_section .text_section p{
    font-size: 16px;
    line-height: 26px;
  }
  .check_story_section .tabs_section{
    margin: 30px 0px;
  }
  .check_story_section .tab_link{
    padding: 8px 10px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
  }
  .check_story_section .tabs_inner_section .tabs_button{
    padding: 10px 25px;
    font-size: 12px;
    line-height: 18px;
  }
  .trusted_brand_section .img_box{
    width: 100%;
    margin-top: 30px;
  }
  .design_templates_section .text_section{
    width: 90%;
    margin: 30px auto;
  }
  .design_templates_section .text_section p{
    font-size: 16px;
    line-height: 26px;
  }
  .item-slider .item-container{
    margin-bottom: 30px;
  }
  .benefit_woosh_section .text_section{
    width: 100%;
    margin: 10px 0px 0px;
  }
  .benefit_woosh_section .text_section p{
    font-size: 16px;
    line-height: 26px;
  }
  .woosh_accrodion_section .chakra-accordion__item .chakra-accordion__button{
    padding: 20px 10px;
  }
  .woosh_accrodion_section .chakra-accordion__item .chakra-accordion__panel{
    padding: 0px 10px 20px 10px;
  }
  .benefit_woosh_section .accordion_section{
    width: 100%;
    margin-bottom: 50px;
  }
  .bottom_stories_section{
    padding: 40px 20px;
    height: unset;
    border-radius: 50px;
    margin-bottom: 40px;
    margin-top: 30px;
  }
  .bottom_stories_section .text_section p{
    font-size: 16px;
    line-height: 26px;
    text-align: center;
  }
  .bottom_stories_section .text_section .btn_stories_login{
    margin-top: 20px;
    padding: 15px 30px;
    font-size: 24px;
    line-height: 32px;
    width: 100%;
    border-radius: 50px;
  }
  .benefit_woosh_section .benefit_box_section{
    width: 100%;
  }
  .bottom_stories_section .text_section{
    width: 100%;
    text-align: center;
  }
  .bottom_stories_section .text_section h2{
    font-size: 16px;
    line-height: 18px;
    width: 60%;
    margin: 0px auto 15px;
    text-align: center;
  }
  .check_story_section .tabs_section .tabs_bg{
    height: 500px;
  }
  .check_story_section{
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .right_section{
    display: none !important;
  }
  .form_section{
    width: 100%;
    padding: 15px !important;
    margin-bottom: 50px;
    border-radius: 25px;
    margin-top: 0px;
  }
  .form_section .main_inner_heading{
    color: #0A83FF;
    margin-bottom: 25px;
  }
  .form_section .field_section label{
    color: #02324F;
    font-weight: 500;
    margin-bottom: 0px;
  }
  .form_section .main_heading{
    font-size: 24px !important;
  }
  .form_section .form_row_section{
    flex-direction: column;
  }
  .form_section .form_row_section .first_section{
    width: 100%;
    margin-right: 0px;
  }
  .form_section .form_row_section .second_section{
    width: 100%;
  }
  .footer_section{
    padding: 40px 0px 20px;
  }
  .footer_section .img_logo{
    width: 50%;
  }
  .footer_web_section{
    display: none;
  }
  .footer_mobile_section{
    display: block;
  }
  .footer_section a{
    font-size: 12px;
  }
  .footer_section p{
    font-size: 14px;
  }
  .main_card{
    display: block;
    margin: 0px 0px 40px;
  }
  .main_leticia{
    padding: 40px 0px 40px;
  }
  .price_card_section{
    width: 100%;
    margin-bottom: 20px;
  }
  .pricing_page .chakra-tabs__tablist{
    width: 100%;
  }
  .not_sure{
    flex-direction: column-reverse;
    margin-top: 30px;
    margin-bottom: 50px;
  }
  .partner_section .text_section .partner_field_section{
    padding: 10px;
  }
  .pricing_plan{
    width: 100%;
    padding-top: 20px;
    text-align: center;
  }
  .text_section p{
    font-size: 12px !important;
    line-height: 16px !important;
  }
  .not_sure .images{
    width: 100%;
  }
  .not_sure .let{
    padding: 0px 0px 0px;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
  }
  .btan{
    padding: 15px 80px !important;
    border-radius: 30px !important;
    margin: 10px 0px 0px !important;
    font-size: 12px !important;
    line-height: 18px !important;
  }
  .pricing_form_section{
    padding: 70px 0px 50px;
  }
  .pricing_form_section .form_bg_section .field_section label{
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 2px;
  }
  .pricing_form_section .form_bg_section .field_section input{
    padding: 8px 10px;
    border-radius: 10px;
    font-size: 12px;
  }
  .pricing_form_section .form_bg_section .field_section select, .pricing_form_section .form_bg_section .field_section textarea{
     padding: 8px 10px;
      border-radius: 10px;
      font-size: 12px;
  }
  .pricing_form_section .form_bg_section .field_section{
    margin-bottom: 20px;
  }
  .pricing_bottom_section .image_section img{
    margin: 0px auto 10px;
  }
  .pricing_page .chakra-tabs__tablist button{
    padding: 10px 40px;
  }
  .header_price img{
    width: 50%;
  }
  .header_price .header_link{
    color: #fff !important;
  }
  .SignUpForm{
    padding-left: 0px !important;
    width: 100%;
  }
  .marquee_bg_section{
    margin: 0px 0px 20px;
    padding: 15px 0px 10px;
  }
  .marquee_bg_section ul li{
    font-size: 14px;
  }
  .craft_captivating_section{
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .trusted_brand_section{
    margin: 40px 0px;
    padding: 20px;
    border-radius: 19px;
  }
  .form_section .btn_section{
    width: 100%;
  }
  .about_page .about_banner_section{
    padding: 30px 0px 10px;
  }
  .carousel.carousel-slider .control-arrow{
    top: 50% !important;
  }
  .about_page .about_banner_section .banner_bg_section{
    padding: 0px;
    border-radius: 25px;
    background-color: unset;
  }
  .about_page .about_banner_section .about_banner_inner_section{
    width: 100%;
    border-radius: 25px;
  }
  .about_page .about_mission_section{
    margin: 50px 0px 0px;
  }
  .about_page .about_mission_section .bg_section{
    border-radius: 25px;
    height: 335px;
    padding: 60px 0px;
  }
  .about_page .bg_section img{
    width: 60% !important;
  }
  .carousel.carousel-slider .control-arrow:hover{
    background-color: unset;
  }
  .about_page .about_business_section{
    margin: 20px 0px;
  }

  .about_page .about_different_section{
    border-radius: 25px;
    margin: 0px 0px 20px;
    padding: 50px 20px;
  }
  .about_page .about_different_section .text_section{
    width: 100%;
  }
  .about_page .about_card_section .card_section{
    width: 350px;
  }
  .about_page .about_business_section .text_section{
    width: 100%;
    margin: 0px;
  }
  .about_page .about_engage_section{
    margin-bottom: 50px;
  }
  .about_page .about_business_section .video_section{
    border-radius: 25px;
    height: 225px;
  }
  .contact_page .contact_banner_section{
    margin: 50px 0px 0px;
    flex-direction: column;
  }
  .contact_page .contact_banner_section .text_section{
    width: 100%;
  }
  .contact_page .contact_banner_section .banner_bg_section{
    width: 100%;
    padding: 20px;
    border-radius: 25px;
  }
  .contact_page .contact_crew_section{
    margin: 30px 0px;
    border-radius: 25px;
    padding: 30px 20px;
  }
  .contact_page .contact_crew_section .text_section{
    width: 100%;
  }
  .contact_page .contact_crew_section img{
    position: unset;
    width: 50%;
    margin: auto;
  }
  .contact_page .contact_crew_section .text_section a img{
    width: unset;
    margin: 0px 0px 0px 10px;
  }
  .contact_page .contact_crew_section .text_section .main_heading{
    text-align: center;
  }
  .contact_page .contact_crew_section .text_section p{
    width: 100%;
    text-align: center !important;
    margin: 0px 0px 10px;
  }
  .contact_page .contact_crew_section .text_section a{
    justify-content: center;
    margin: 0px 0px 10px;
  }
  .contact_page .contact_connect_section{
    margin: 40px 0px;
  }
  .contact_page .contact_connect_section .text_section{
    margin-bottom: 25px;
  }
  .contact_page .contact_connect_section .connect_box_section{
    width: 95px;
    height: 95px;
    margin-right: 0px;
    margin-bottom: 20px;
    border-radius: 30px;
  }
  .contact_page .contact_connect_section .connect_box_section img{
    width: 40%;
  }
  .contact_page .contact_power_section{
    padding: 40px 20px;
    border-radius: 25px;
    text-align: center;
  }
  .form_section{
    background-color: #CEE6FF;
    box-shadow: unset;
  }
  .SignUpForm input, .SignUpForm select{
    border-bottom: 1px solid #fff;
  }
  .form_section .btn_create{
    border-radius: 30px;
    font-size: 12px;
    line-height: 18px;
    padding: 12px 30px;
  }
  .contact_page .contact_dive_section{
    margin: 50px 0px;
  }
  .partner_section{
    padding: 50px 0px;
  }
  .partner_section .text_section{
    width: 100%;
  }
  .pricing_form_section{
    flex-direction: column !important;
  }
  .pricing_form_section .text_section{
    width: 100%;
  }
  .pricing_form_section .form_bg_section{
    width: 100%;
    padding: 30px 20px;
    border-radius: 25px;
  }
  .pricing_bottom_section{
    margin: 0px 0px 50px;
    flex-direction: column !important;
  }
  .pricing_bottom_section .text_section{
    width: 90%;
  }
  .pricing_bottom_section .image_section{
    width: 100%;
  }
  .not_sure .images img{
    float: unset;
  }
  .pricing_bottom_section .text_section .inner_para_section{
    width: 100%;
    border-left: 0px;
    padding-left: 0px;
  }
  .pricing_bottom_section .text_section .inner_para_section p{
    text-align: center !important;
  }
  .pricing_bottom_section .text_section p{
    padding-bottom: 5px;
  }
  .contact_page .contact_banner_section .banner_bg_section .field_section label{
    margin-bottom: 3px;
  }
  .contact_page .contact_banner_section .banner_bg_section .field_section input, .contact_page .contact_banner_section .banner_bg_section .field_section select, .contact_page .contact_banner_section .banner_bg_section .field_section textarea{
    border: 0px;
    border-bottom: 1px solid #fff;
    border-radius: 0px;
    padding: 6px 12px;
  }
}
